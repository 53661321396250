/*
spacer - Задаёт фиксированные отступы по краям контейнера.

Использование в шаблоне:

<div class="container container_spacer">
  ...
</div>

По умолчанию в качестве единиц измерения для отступа используются - 'px'.
При необходимости их можно изменить переменной $spacer-unit в файлике ../_common/_container.scss
*/

$grid: (
  small: (
    from: 0,
    to: 767
  ),
  medium: (
    from: 768,
    to: 1199
  ),
  large: (
    from: 1200
  )
);

:root {
  --body-font-size: 16px;
  --body-line-height: 1.3;

  --font: "Arial", sans-serif;
  --h-font: "Arial", sans-serif;

  --t: transparent;

  --primary-color: #01c767;
  --alert-color: #b50000;
  --default-transition: all 0.4s var(--default-transition-function);
  --default-transition-function: ease-in-out;
  --primary-color-opacity: rgba(1, 199, 103, .85);
}
